import { useEffect, useState } from "react";
import { getAccountBalance, getRecentTransactions } from "../../utils/utils";
import {
  Center,
  Spinner,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  Card,
  Divider,
  Tr,
  Td,
  Th,
  Tbody,
  Thead,
  Table,
  TableContainer,
  Tag,
  Alert,
  AlertIcon,
  AlertDescription,
  Select,
} from "@chakra-ui/react";

export const Accounts = ({
  selectedAccount,
  userAccounts,
  updateSelectedAccount,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ isError: false });
  const [transactions, setTransactions] = useState();
  const [showBalance, setShowBalance] = useState(false);
  const [balanceDetails, setBalanceDetails] = useState({
    loading: false,
    balance: "",
  });

  useEffect(() => {
    if (showBalance) {
      setBalanceDetails({ loading: true, balance: "" });
      getAccountBalance(selectedAccount.accountNumber)
        .then((res) => res.json())
        .then((response) => {
          if (response.status === "Success") {
            setBalanceDetails({
              loading: false,
              balance: response.currentBalance,
            });
          } else {
            setBalanceDetails({
              loading: false,
              balance: "Couldn't fetch!",
            });
          }
        })
        .catch((err) => {
          setBalanceDetails({
            loading: false,
            balance: "",
          });
          setError({
            isError: true,
            message: err.toString(),
          });
        });
    } else {
      setBalanceDetails({
        loading: false,
        balance: "",
      });
    }
  }, [selectedAccount.accountNumber, showBalance]);

  useEffect(() => {
    setLoading(true);
    getRecentTransactions(selectedAccount.accountNumber)
      .then((res) => res.json())
      .then((recentTransactions) => {
        if (recentTransactions.status === "Success") {
          setTransactions(recentTransactions.transactions);
          setLoading(false);
        } else {
          setError({
            isError: true,
            message: recentTransactions.errorMessage,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setError({
          isError: true,
          message: err.toString(),
        });
        setLoading(false);
      });
  }, [selectedAccount]);

  if (loading) {
    return (
      <Center p={10}>
        <Spinner />
      </Center>
    );
  }

  if (error.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <Card maxW="container.2xl" p={5} mb={5} variant="outline">
        <>
          <Flex justify="space-between" gap={2}>
            <Flex direction="column" gap={10}>
              <div>
                <FormControl>
                  <Text fontWeight={600}>Switch Account</Text>
                  <Select
                    width="15em"
                    value={selectedAccount.accountNumber}
                    onChange={(e) => updateSelectedAccount(e.target.value)}
                  >
                    {userAccounts.map((acc) => (
                      <option key={acc.accountNumber} value={acc.accountNumber}>
                        {acc.accountNumber}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <Flex justify="space-between" gap={20}>
                  <div>
                    <Text fontSize="sm">Branch Name</Text>
                    <Text>{selectedAccount.branch}</Text>
                  </div>
                  <div>
                    <Text fontSize="sm">IFSC Code</Text>
                    <Text>{selectedAccount.ifscCode}</Text>
                  </div>
                </Flex>
              </div>
            </Flex>
            <div>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="show-balance" mb="0">
                  Show Account Balance
                </FormLabel>
                <Switch
                  id="show-balance"
                  onChange={(e) => {
                    setShowBalance(e.target.checked);
                  }}
                />
              </FormControl>
              {balanceDetails.loading ? (
                <Spinner />
              ) : (
                <Text fontSize="1.4em" as="b" color="blue">
                  {balanceDetails.balance}
                </Text>
              )}
            </div>
          </Flex>
        </>
      </Card>
      <Divider />
      <Text fontSize="1.3em" p={5}>
        Recent Transactions
      </Text>
      <TableContainer>
        <Table variant="simple">
          <Thead backgroundColor="gray.100">
            <Tr>
              <Th>Date</Th>
              <Th>Remarks</Th>
              <Th>Type</Th>
              <Th>Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions.map((transaction) => (
              <Tr key={transaction.isoDate}>
                <Td>{transaction.isoDate}</Td>
                <Td>{transaction.remarks}</Td>
                <Td>
                  {transaction.crediOrDebit === "cr" ? (
                    <Tag colorScheme="teal">Credit</Tag>
                  ) : (
                    <Tag colorScheme="red">Debit</Tag>
                  )}
                </Td>
                <Td>{transaction.amount}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
