import { useCallback, useEffect, useState } from "react";
import { getRecentTransactions } from "../../utils/utils";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Card,
  CardHeader,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { quickLinks } from "../../constants";
import { images } from "../../utils/utils";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

export const Transactions = ({ userAccounts }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ isError: false });
  const [transactions, setTransactions] = useState();
  const [currentView, setCurrentView] = useState("lastTen");
  const [currentOffer, setCurrentOffer] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState(
    userAccounts[2]?userAccounts[2].accountNumber:userAccounts[0].accountNumber
  );

  const getTransactions = useCallback(() => {
    setLoading(true);
    getRecentTransactions(selectedAccount)
      .then((res) => res.json())
      .then((recentTransactions) => {
        if (recentTransactions.status === "Success") {
          setTransactions(recentTransactions.transactions);
          setLoading(false);
        } else {
          setError({
            isError: true,
            message: recentTransactions.errorMessage,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setError({
          isError: true,
          message: err.toString(),
        });
        setLoading(false);
      });
  }, [selectedAccount]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  if (loading) {
    return (
      <Center p={10}>
        <Spinner />
      </Center>
    );
  }

  if (error.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <Flex align="flex-start" gap={3}>
        <Flex direction="column" gap={3}>
          <Card variant="outline">
            <CardHeader>
              <Heading size="md">Welcome, Rahul Kumar</Heading>
              <Text pt="2" fontSize="sm">
                Last login was at 11-10-2023, 5:00 pm
              </Text>
            </CardHeader>
          </Card>
          <Card variant="outline">
            <CardHeader>
              <Heading size="sm">Quick Links</Heading>
              <Divider mt={3} mb={3} color="gray.200" />
              {quickLinks.map((link, idx) => (
                <Text
                  key={idx}
                  fontSize="sm"
                  color="blue.400"
                  cursor="pointer"
                  mb={1}
                >
                  {link}
                </Text>
              ))}
            </CardHeader>
          </Card>
          <Card variant="outline">
            <CardHeader>
              <Heading size="sm">Offers</Heading>
              <Divider mt={3} mb={3} color="gray.200" />
              <Image
                src={
                  currentOffer === 0
                    ? images["offer-2.png"]
                    : images["offer-3.png"]
                }
                alt="offer"
              />
              <Flex gap={2} align="center" justify="center" mt={3}>
                <IconButton
                  aria-label="Previous Offer"
                  icon={<ArrowBackIcon />}
                  p={0}
                  boxSize={8}
                  onClick={() => setCurrentOffer((cur) => (cur === 0 ? 1 : 0))}
                />
                <IconButton
                  aria-label="Next Offer"
                  icon={<ArrowForwardIcon />}
                  p={0}
                  boxSize={8}
                  onClick={() => setCurrentOffer((cur) => (cur === 0 ? 1 : 0))}
                />
              </Flex>
            </CardHeader>
          </Card>
        </Flex>
        <Card flex={3} p={5} variant="outline">
          <Container maxW="container.2xl">
            <Heading size="md" mb={5}>
              My Transactions
            </Heading>
            <Flex alignItems="center" gap={3} mb={5}>
              <Text>Account Number:</Text>
              <Select
                width="auto"
                value={selectedAccount}
                onChange={(e) => setSelectedAccount(e.target.value)}
              >
                {userAccounts.map((acc) => (
                  <option key={acc.accountNumber} value={acc.accountNumber}>
                    {acc.accountNumber}
                  </option>
                ))}
              </Select>
            </Flex>
            <RadioGroup
              value={currentView}
              onChange={(value) => {
                setCurrentView(value);
              }}
              mb={5}
            >
              <Stack spacing={5} direction="row">
                <Radio value="lastTen">Last 10 Transactions</Radio>
                <Radio value="custom">Custom Date</Radio>
              </Stack>
            </RadioGroup>
            {currentView === "custom" && (
              <>
                <Flex alignItems="center" gap={3} mb={5}>
                  <Text>From:</Text>
                  <Input type="datetime-local" width="auto" />
                  <Text>To:</Text>
                  <Input type="datetime-local" width="auto" />
                  <Button colorScheme="blue" onClick={getTransactions}>
                    Search
                  </Button>
                </Flex>
              </>
            )}
          </Container>
          <Divider mt={2} mb={2} color="gray.200" />
          <TableContainer pt={5}>
            <Table variant="simple">
              <Thead backgroundColor="gray.100">
                <Tr>
                  <Th>Date</Th>
                  <Th>Remarks</Th>
                  <Th>Type</Th>
                  <Th>Amount</Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactions.map((transaction) => (
                  <Tr key={transaction.isoDate}>
                    <Td>{transaction.isoDate}</Td>
                    <Td>{transaction.remarks}</Td>
                    <Td>
                      {transaction.crediOrDebit === "cr" ? (
                        <Tag colorScheme="teal">Credit</Tag>
                      ) : (
                        <Tag colorScheme="red">Debit</Tag>
                      )}
                    </Td>
                    <Td>{transaction.amount}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      </Flex>
    </>
  );
};
