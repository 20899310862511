import { ChakraProvider } from "@chakra-ui/react";
import LandingPage from "./components/LandingPage";
import Login from "./components/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserDetails } from "./components/userDetails";

function App() {
  return (
    <Router>
      <ChakraProvider>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<LandingPage />}></Route>
            <Route exact path="/login" element={<Login />}></Route>
            <Route exact path="/userDetails" element={<UserDetails />}></Route>
          </Routes>
        </div>
      </ChakraProvider>
    </Router>
  );
}

export default App;
