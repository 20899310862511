import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Card,
  CardHeader,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { quickLinks } from "../../constants";
import { getPayees, images, transferFunds } from "../../utils/utils";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";

export const FundTransfer = ({ userAccounts }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ isError: false });
  const [payees, setPayees] = useState();
  const [currentOffer, setCurrentOffer] = useState(0);
  const [fromAccount, setFromAccount] = useState();
  const [payeeIdToTransfer, setPayeeIdToTransfer] = useState();
  const [transferAmount, setTransferAmount] = useState(1);
  const toast = useToast();

  const getPayeeList = useCallback(() => {
    getPayees()
      .then((res) => res.json())
      .then((response) => {
        if (response.status === "Success") {
          setPayees(response.payees);
          setLoading(false);
        } else {
          setLoading(false);
          setError({ isError: true, message: response.errorMessage });
        }
      })
      .catch((err) => {
        setLoading(false);
        setError({ isError: true, message: err.toString() });
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    getPayeeList();
  }, [getPayeeList]);

  const getPayeeDetails = (id) => {
    let payee = payees.find((payee) => payee.id.toString() === id);
    return (
      <Container mb={5} maxW="container.2xl">
        <Text fontSize="22px" fontWeight={600} mt={5} mb={5}>
          Payee Details
        </Text>
        <Flex gap={40}>
          <div>
            <Text fontSize="18px" fontWeight={600} mb={2}>
              Payee Bank IFSC
            </Text>
            <Text>{payee.ifscCode}</Text>
          </div>
          <div>
            <Text fontSize="18px" fontWeight={600} mb={2}>
              Payee Bank Name
            </Text>
            <Text>{payee.bankName}</Text>
          </div>
        </Flex>
      </Container>
    );
  };

  const transfer = () => {
    setLoading(true);
    const payload = {
      from: fromAccount,
      to: payeeIdToTransfer,
      amount: transferAmount,
      transferType: "IMPS",
    };
    transferFunds(fromAccount, payload)
      .then((res) => res.json())
      .then((response) => {
        if (response.status === "Success") {
          toast({
            title: "Success!",
            description: `Fund transfer of ${transferAmount} success from Acct. ${fromAccount}`,
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          setLoading(false);
          setFromAccount(undefined);
          setPayeeIdToTransfer(undefined);
          setTransferAmount(1);
        } else {
          setLoading(false);
          setError({ isError: true, message: response.errorMessage });
        }
      })
      .catch((err) => {
        setLoading(false);
        setError({ isError: true, message: err.toString() });
      });
  };

  if (loading) {
    return (
      <Center p={10}>
        <Spinner />
      </Center>
    );
  }

  if (error.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <Flex align="flex-start" gap={3}>
        <Flex direction="column" gap={3}>
          <Card variant="outline">
            <CardHeader>
              <Heading size="md">Welcome, Rahul Kumar</Heading>
              <Text pt="2" fontSize="sm">
                Last login was at 11-10-2023, 5:00 pm
              </Text>
            </CardHeader>
          </Card>
          <Card variant="outline">
            <CardHeader>
              <Heading size="sm">Quick Links</Heading>
              <Divider mt={3} mb={3} color="gray.200" />
              {quickLinks.map((link, idx) => (
                <Text
                  key={idx}
                  fontSize="sm"
                  color="blue.400"
                  cursor="pointer"
                  mb={1}
                >
                  {link}
                </Text>
              ))}
            </CardHeader>
          </Card>
          <Card variant="outline">
            <CardHeader>
              <Heading size="sm">Offers</Heading>
              <Divider mt={3} mb={3} color="gray.200" />
              <Image
                src={
                  currentOffer === 0
                    ? images["offer-2.png"]
                    : images["offer-3.png"]
                }
                alt="offer"
              />
              <Flex gap={2} align="center" justify="center" mt={3}>
                <IconButton
                  aria-label="Previous Offer"
                  icon={<ArrowBackIcon />}
                  p={0}
                  boxSize={8}
                  onClick={() => setCurrentOffer((cur) => (cur === 0 ? 1 : 0))}
                />
                <IconButton
                  aria-label="Next Offer"
                  icon={<ArrowForwardIcon />}
                  p={0}
                  boxSize={8}
                  onClick={() => setCurrentOffer((cur) => (cur === 0 ? 1 : 0))}
                />
              </Flex>
            </CardHeader>
          </Card>
        </Flex>
        <Card flex={3} p={5} variant="outline">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              transfer();
            }}
          >
            <Container maxW="container.2xl" mb={5}>
              <Text fontSize="22px" fontWeight={600} mt={5} mb={5}>
                Fund Transfer
              </Text>
              <FormControl isRequired>
                <FormLabel mt={2}>Select Payee</FormLabel>
                <Select
                  width="15em"
                  value={payeeIdToTransfer}
                  onChange={(e) => setPayeeIdToTransfer(e.target.value)}
                  placeholder="-- Payee Name --"
                >
                  {payees.map((payee) => (
                    <option key={payee.payeeAccountNumber} value={payee.id}>
                      {payee.payeeAccountName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Container>
            <Divider color="gray.200" />
            <Container maxW="container.2xl" mb={5}>
              <Text fontSize="22px" fontWeight={600} mt={5} mb={5}>
                Transaction Details
              </Text>
              <Flex gap={5}>
                <div>
                  <FormControl isRequired>
                    <FormLabel mt={2}>Transfer From</FormLabel>
                    <Select
                      maxW="15em"
                      value={fromAccount}
                      onChange={(e) => setFromAccount(e.target.value)}
                      placeholder="-- Account Number --"
                    >
                      {userAccounts.map((acc) => (
                        <option key={acc.accountNumber} value={acc.accountNumber}>
                          {acc.accountNumber}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl isRequired>
                    <FormLabel mt={2}>Amount</FormLabel>
                    <NumberInput
                      required
                      maxW="12em"
                      placeholder="In Rupee"
                      type="number"
                      min={1}
                      value={transferAmount}
                      onChange={(value) => setTransferAmount(value)}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </div>
                <div>
                  <FormControl>
                    <FormLabel mt={2}>Remarks</FormLabel>
                    <Input width="24em" placeholder="Optional" />
                  </FormControl>
                </div>
              </Flex>
            </Container>
            <Divider color="gray.200" />
            {payeeIdToTransfer && getPayeeDetails(payeeIdToTransfer)}
            <Flex justify="flex-end" gap={2}>
              <Button type="submit" colorScheme="blue">
                Proceed to Pay
              </Button>
            </Flex>
          </form>
        </Card>
      </Flex>
    </>
  );
};
