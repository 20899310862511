import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Image,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { images } from "../utils/utils";
import appstore from "../images/app-store-img 1.png";
import playstore from "../images/google-play-img .png";
import phonehand from "../images/phone-hand.png";
import blog1 from "../images/blog1.png";
import blog2 from "../images/blog2.png";
import blog3 from "../images/blog3.png";
import blog4 from "../images/blog4.png";
import followus from "../images/follow-us.svg";
import "../style.css";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <Container maxW="container.2xl" p={0}>
      <Flex align="flex-start" p={4}>
        <Image src={images["logo.png"]} alt="logo" />
        <Spacer />
        <Button colorScheme="blue" onClick={navigateToLogin}>
          Log In
        </Button>
      </Flex>
      <Divider />
      <Image src={images["mainBanner.png"]} alt="Main Banner" />
      <Container maxW="container.2xl" p={5}>
        <Text fontSize="1.5em" textAlign={["center"]} p={5} fontWeight={600}>
          Why choose us vuBank? 
        </Text>
        <Flex justify="space-around" p={5}>
          <Box>
            <Image src={images["whyChooseUs-1.png"]} />
            <Text textAlign={["center"]} fontSize={22} color={"pink.500"} fontWeight={500} p={5}>Advance</Text>
          </Box>
          <Box>
            <Image src={images["whyChooseUs-2.png"]} />
            <Text textAlign={["center"]} fontSize={22} color={"pink.500"} fontWeight={500} p={5}>Innovative</Text>
          </Box>
          <Box>
            <Image src={images["whyChooseUs-3.png"]} />
            <Text textAlign={["center"]} fontSize={22} color={"pink.500"} fontWeight={500} p={5}>Instant</Text>
          </Box>
        </Flex>
      </Container>
      <Container maxW="container.2xl" p={5}>
        <Text fontSize="2em" textAlign={["center"]} p={5} fontWeight={600}>
          Exclusive Offers!
        </Text>
        <Flex justify="space-around" p={5}>
          <Box borderWidth='1px' borderRadius='lg'>
            <Image src={images["offer-1.png"]} />
            <Text textAlign={["left"]} pt={5} pl={5}>Offer ends: 16 January 2024</Text>
            <Text textAlign={["left"]} p={5}
              fontWeight='semibold' maxW={300}
              as='h4'
              lineHeight='tight'
              noOfLines={2}>Flat Rs.75 off on orders above Rs.500 using vuBank Debit Cards.</Text>
          </Box>
          <Box borderWidth='1px' borderRadius='lg'>
            <Image src={images["offer-2.png"]} />
            <Text textAlign={["left"]} pt={5} pl={5}>Offer ends: 02 January 2024</Text>
            <Text textAlign={["left"]} p={5}
              fontWeight='semibold' maxW={300}
              as='h4'
              lineHeight='tight'
              noOfLines={2}>Domestic Flight -Flat 12% Instant Discount on vuBank Debit Cards.</Text>
          </Box>
          <Box borderWidth='1px' borderRadius='lg'>
            <Image src={images["offer-3.png"]} />
            <Text textAlign={["left"]} pt={5} pl={5}>Offer ends: 10 January 2024</Text>
            <Text textAlign={["left"]} p={5}
              fontWeight='semibold' maxW={300}
              as='h4'
              lineHeight='tight'
              noOfLines={2}>Get 25% discount of upto Rs 100 on the booking of min 2 tickets.</Text>
          </Box>
        </Flex>
      </Container>
      {/* <div
        style={{
          background: "#DA1B5017",
          width: "2000px",
          height: "400px",
          position: "relative",
          display: "flex",
        }}
      > */}
      <Container maxW="container.2xl" bg="#da1b5017">
        <Flex justifyContent={"space-around"}>
          <Box p={5} >
            <Text fontSize={22} fontWeight={700} mt={5}>The Bank is an App!</Text>
            <Text fontSize={22} fontWeight={700} mt={5} mb={5}>Features:</Text>
            <UnorderedList class="features" >
              <ListItem className="item">Fund Transfers</ListItem>
              <ListItem className="item">Balance Enquiry</ListItem>
              <ListItem className="item">Recharge and Bill Pay</ListItem>
            </UnorderedList>
            <Text className="download-text">
              Download the vuBank One Mobile App and simplify your banking.
            </Text>
            <Image mb={5}src={appstore} />
            <Image  src={playstore} />
          </Box>
          <Box>
            <Image mt={12} src={phonehand} />
          </Box>
        </Flex>
      </Container>
      <Container maxW="container.2xl" textAlign={["center"]} maxH={52} p={2} style={{ background: "#C91A72" }}>
        <Text color="white" as='b'>
          Now enjoy interest rates upto 7% p.a.* on your savings account . Open
          an account
        </Text>
      </Container>
      <Text textAlign={["center"]} fontWeight="bold" fontSize={20} p={5}style={{ padding: "20px", marginLeft: "0" }}>Blogs</Text>
      <Container maxW="container.3xl">
        <Flex justifyContent={"space-evenly"}>
        <Box>
          <Image src={blog1} />
          <Text p={2} maxW={247} fontSize={20}>
            5 Types of Regulatory Reporting for Banks
          </Text>
        </Box>
        <Box>
          <Image src={blog2} />
          <Text p={2} maxW={240} fontSize={20}>Top 10 Fintech Trends for 2023</Text>
        </Box>
        <Box>
          <Image src={blog3} />
          <Text p={2} maxW={247} fontSize={20}>
            The Top Co-Operative Banks in India
          </Text>
        </Box>
        <Box>
          <Image src={blog4} />
          <Text p={2} maxW={247} fontSize={20}>
            No-Code Revolution in the Fintech Industry
          </Text>
        </Box>
        </Flex>
      </Container>
      <Container style={{ background: "#484848"}} maxW="container.2xl">
        <Flex justifyContent={"space-evenly"}>
        <Box p={5}>
          <Text color={"white"} pt={2} pb={2}>About Vubank</Text>
          <Text color={"white"}>vubank app </Text>
          <Text color={"white"}>follow us </Text>
          <Image mt={2} src={followus} />
        </Box>
        <Box p={5}>
          <Text color={"white"} pt={2} pb={2}>Our Products</Text>
          <Text color={"white"}>Savings account</Text>
          <Text color={"white"}>personal loan </Text>
          <Text color={"white"}>fixed deposit </Text>
          <Text color={"white"}>recharge and pay bill</Text>
        </Box>
        <Box p={5}>
          <Text color={"white"} pt={2} pb={2}>About Us</Text>
          <Text color={"white"}>Disclaimer</Text>
          <Text color={"white"}>Privacy Policy</Text>
          <Text color={"white"}>Terms of use </Text>
          <Text color={"white"}>FAQ</Text>
        </Box>
        <Box p={5}>
          <Text color={"white"} pt={2} pb={2}>Customer Service</Text>
          <Text color={"white"}>Contact Us</Text>
          <Text color={"white"}>Locate Us</Text>
        </Box>
        <Box p={5}>
          <Text className="about-us-title">Download vuBank</Text>
          <Image  src={appstore} />
          <Image mt={2} src={playstore} />
        </Box>
        </Flex>
      </Container>
    </Container>
  );
}

export default LandingPage;
