import { baseUrl } from "../constants";

const importAll = (r) => {
  let images = {};
  // r.keys() returns all the matching file paths
  r.keys().forEach((item) => {
    // remove prefix for each file path
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

export const images = importAll(
  // require.context is provided by Webpack
  // The first argument is the directory to search within ("./images" in this case).
  // The second argument (false) specifies that the search should not include subdirectories.
  // The third argument is a regular expression (/\.png|jpe?g|svg$/) specifying the file extensions to match (.png, .jpg, .jpeg, and .svg).
  require.context("../images", false, /\.(png|jpe?g|svg)$/)
);

export const getUserAccounts = () => {
  const url = baseUrl + "accounts";
  const token = `Bearer ${localStorage.getItem("token")}`;
  const options = {
    headers: {
      Authorization: token,
    },
  };
  return fetch(url, options);
};

export const getRecentTransactions = (accountNumber) => {
  const url = baseUrl + `accounts/${accountNumber}/statement`;
  const token = `Bearer ${localStorage.getItem("token")}`;
  const options = {
    headers: {
      Authorization: token,
    },
  };
  return fetch(url, options);
};

export const getAccountBalance = (accountNumber) => {
  const url = baseUrl + `accounts/${accountNumber}/balance`;
  const token = `Bearer ${localStorage.getItem("token")}`;
  const options = {
    headers: {
      Authorization: token,
    },
  };
  return fetch(url, options);
};

export const getPayees = () => {
  const url = baseUrl + "payees";
  const token = `Bearer ${localStorage.getItem("token")}`;
  const options = {
    headers: {
      Authorization: token,
    },
  };
  return fetch(url, options);
};

export const addNewPayee = (payload) => {
  const url = baseUrl + "payees";
  const token = `Bearer ${localStorage.getItem("token")}`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload),
  });
};

export const deleteExistingPayee = (id) => {
  const url = baseUrl + `payees/${id}`;
  const token = `Bearer ${localStorage.getItem("token")}`;
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
};

export const transferFunds = (accountNumber, payload) => {
  const url = baseUrl + `accounts/${accountNumber}/fundtransfer`;
  const token = `Bearer ${localStorage.getItem("token")}`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload),
  });
};
