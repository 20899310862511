import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Image,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { getUserAccounts, images } from "../utils/utils";
import { Accounts } from "./userDetailTabs/Accounts";
import { ManagePayees } from "./userDetailTabs/ManagePayees";
import { Transactions } from "./userDetailTabs/Transactions";
import { FundTransfer } from "./userDetailTabs/FundTransfer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const UserDetails = () => {
  const [loading, setLoading] = useState(true);
  const [userAccounts, setUserAccounts] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [error, setError] = useState({ isError: false });
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getUserAccounts()
      .then((res) => res.json())
      .then((accountsData) => {
        if (accountsData.status === "Success") {
          setUserAccounts(accountsData.accounts);
          setSelectedAccount(accountsData.accounts[0]);
          setLoading(false);
        } else {
          setError({ isError: true, message: "Failed to get accounts!" });
          setLoading(false);
        }
      })
      .catch((err) => {
        setError({ isError: true, message: err.toString() });
        setLoading(false);
      });
  }, []);

  const updateSelectedAccount = (value) => {
    const account = userAccounts.find((acct) => acct.accountNumber === value);
    setSelectedAccount(account);
  };

  return (
    <Container maxW="container.2xl" p={0}>
      <Flex align="flex-start" p={4} gap={5}>
        <Image src={images["logo.png"]} alt="logo" />
        <Spacer />
        {!loading && !error.isError && (
          <Flex direction="column">
            <Text fontSize="1em" fontWeight="600">
              Hi, {selectedAccount.name}
            </Text>
            <Text fontSize="xs">Last Login 10 Oct 2023 4:00 pm</Text>
          </Flex>
        )}
        <Button
          colorScheme="blue"
          onClick={() => {
            localStorage.setItem("token", "");
            navigate("/login");
          }}
        >
          Log Out
        </Button>
      </Flex>
      <Divider />
      {loading ? (
        <Center p={5}>
          <Spinner />
        </Center>
      ) : error.isError ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      ) : (
        <Container maxW="container.2xl" p={5}>
          <Tabs colorScheme="blue" isLazy lazyBehavior="unmount">
            <TabList>
              <Tab>Accounts</Tab>
              <Tab>Fund Transfer</Tab>
              <Tab>Transactions</Tab>
              <Tab>Manage Payees</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Accounts
                  selectedAccount={selectedAccount}
                  userAccounts={userAccounts}
                  updateSelectedAccount={updateSelectedAccount}
                />
              </TabPanel>
              <TabPanel>
                <FundTransfer userAccounts={userAccounts} />
              </TabPanel>
              <TabPanel>
                <Transactions userAccounts={userAccounts} />
              </TabPanel>
              <TabPanel>
                <ManagePayees />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      )}
    </Container>
  );
};
