export const baseUrl =
  process.env.REACT_APP_FRONT_END_URL || "http://164.52.205.50/api/";

export const quickLinks = [
  "Account Balance",
  "View Statement",
  "Fund Transfer",
  "Manage Beneficiary",
  "Set Debit Card Limit",
  "Profile",
  "Check CIBIL Score",
  "Instant Loan",
  "Interest Calculator",
];

export const payeeInfo = {
  payeeAccountName: "",
  nickName: "",
  payeeAccountNumber: "",
  bankName: "",
  ifscCode: "",
};
