import logo from "../images/logo.png";
import BankImg from "../images/bankImg.png";
import logoImg from "../images/logo-img.png";
import { useState } from "react";
import { baseUrl } from "../constants";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Container,
  Box,
  Button,
  Image,
  Flex,
  Text,
  Input
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ isError: false });
  const navigate = useNavigate();

  const handleLogin = (username, password) => {
    const url = baseUrl + "auth/login";
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params.toString(),
    };
    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "Success") {
          localStorage.setItem("token", data.token);
          <Alert status="success" m={10}>
            <AlertIcon />
            <AlertDescription>Success!</AlertDescription>
          </Alert>;
          navigate("/userDetails");
        } else {
          setError({ isError: true, message: data.errorMessage });
        }
      })
      .catch((err) => setError({ isError: true, message: err.toString() }));
  };

  if (error.isError) {
    return (
      <Container maxW="container.2xl" p={5}>
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxW="container.2xl" style={{ background: "#ededed", height: "1000px" }}>
      <Box>
        <Image p={5} src={logo} />
      </Box>
      <Container maxW="container.2xl">
        <Flex justifyContent={"space-evenly"}>
          <Image p={2} src={BankImg} />
          <Box>
            <Text textAlign={["center"]} fontSize={32} style={{ color: "#11319C" }}>Hi, Welcome!</Text>
            <Text fontSize={20} textAlign={["center"]} style={{ color: "#293646" }} fontWeight={"bold"} mb={10} >Log In to Your vuBank Account</Text>
            <Box position={"relative"} padding={10} overflow={"hidden"} gap={5} flexDirection={"column"} display={"inline-flex"} border={0.82} background={"white"} borderRadius={6.58} style={{ borderColor: "#d0d0d0" }}>
              <Text textAlign={['center']} style={{color:"#293646"}} fontSize={20} fontWeight={"bold"}>Log In</Text>
              <Text>Username or email</Text>
              <Input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <Text>Password</Text>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                color={"white"}
                style={{ background: "#11319C" }}
                onClick={() => handleLogin(userName, password)}
              >
                Log in
              </Button>
              <Box>
                <Flex justifyContent={"space-around"}>
                <Image src={logoImg} />
                <Text className="login-credits">
                  Powered by vunet systems - 2023. all rights reserved.
                </Text>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Container>
  );
}

export default Login;
