import { useCallback, useEffect, useState } from "react";
import { addNewPayee, deleteExistingPayee, getPayees } from "../../utils/utils";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Card,
  CardHeader,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  AddIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import { payeeInfo, quickLinks } from "../../constants";
import { images } from "../../utils/utils";

export const ManagePayees = () => {
  const [loading, setLoading] = useState(true);
  const [payees, setPayees] = useState([]);
  const [error, setError] = useState({ isError: false });
  const [currentOffer, setCurrentOffer] = useState(0);
  const [showAddPayeeModal, setShowAddPayeeModal] = useState(false);
  const [payeeDetails, setPayeeDetails] = useState(payeeInfo);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const getPayeeList = useCallback(() => {
    getPayees()
      .then((res) => res.json())
      .then((response) => {
        if (response.status === "Success") {
          setPayees(response.payees);
          setFilteredData(response.payees);
          setLoading(false);
        } else {
          setLoading(false);
          setError({ isError: true, message: response.errorMessage });
        }
      })
      .catch((err) => {
        setLoading(false);
        setError({ isError: true, message: err.toString() });
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    getPayeeList();
  }, [getPayeeList]);

  useEffect(() => {
    let updatedPayees = [...payees];
    updatedPayees = updatedPayees.filter((payee) =>
      payee.payeeAccountName.toLowerCase().includes(search.trim().toLowerCase())
    );
    setFilteredData(updatedPayees);
  }, [search, payees]);

  const addPayee = () => {
    setLoading(true);
    setShowAddPayeeModal(false);
    addNewPayee(payeeDetails)
      .then((res) => res.json())
      .then((response) => {
        if (response.status === "Success") {
          setPayeeDetails(payeeInfo);
          getPayeeList();
        } else {
          setError({
            isError: true,
            message: response.errorMessage,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setError({ isError: true, message: err.toString() });
        setLoading(false);
      });
  };

  const deletePayee = (id) => {
    setLoading(true);
    deleteExistingPayee(id)
      .then((res) => res.json())
      .then((response) => {
        if (response.status === "Success") {
          getPayeeList();
        } else {
          setError({
            isError: true,
            message: response.errorMessage,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError({ isError: true, message: err.toString() });
      });
  };

  if (loading) {
    return (
      <Center p={10}>
        <Spinner />
      </Center>
    );
  }

  if (error.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <Flex align="flex-start" gap={3}>
      <Flex direction="column" gap={3}>
        <Card variant="outline">
          <CardHeader>
            <Heading size="md">Welcome, Rahul Kumar</Heading>
            <Text pt="2" fontSize="sm">
              Last login was at 11-10-2023, 5:00 pm
            </Text>
          </CardHeader>
        </Card>
        <Card variant="outline">
          <CardHeader>
            <Heading size="sm">Quick Links</Heading>
            <Divider mt={3} mb={3} />
            {quickLinks.map((link, idx) => (
              <Text
                key={idx}
                fontSize="sm"
                color="blue.400"
                cursor="pointer"
                mb={1}
              >
                {link}
              </Text>
            ))}
          </CardHeader>
        </Card>
        <Card variant="outline">
          <CardHeader>
            <Heading size="sm">Offers</Heading>
            <Divider mt={3} mb={3} color="gray.200" />
            <Image
              src={
                currentOffer === 0
                  ? images["offer-2.png"]
                  : images["offer-3.png"]
              }
              alt="offer"
            />
            <Flex gap={2} align="center" justify="center" mt={3}>
              <IconButton
                aria-label="Previous Offer"
                icon={<ArrowBackIcon />}
                p={0}
                boxSize={8}
                onClick={() => setCurrentOffer((cur) => (cur === 0 ? 1 : 0))}
              />
              <IconButton
                aria-label="Next Offer"
                icon={<ArrowForwardIcon />}
                p={0}
                boxSize={8}
                onClick={() => setCurrentOffer((cur) => (cur === 0 ? 1 : 0))}
              />
            </Flex>
          </CardHeader>
        </Card>
      </Flex>
      <Card flex={3} p={5} variant="outline">
        <Flex justify="space-between" grow={false}>
          <Input
            size="md"
            width="25rem"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            colorScheme="blue"
            leftIcon={<AddIcon />}
            onClick={() => setShowAddPayeeModal(true)}
          >
            Add Payee
          </Button>
        </Flex>
        <TableContainer pt={5}>
          <Table variant="simple">
            <Thead backgroundColor="gray.100">
              <Tr>
                <Th>Payee Name</Th>
                <Th>Bank Name</Th>
                <Th>Account Number</Th>
                <Th>IFSC Code</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredData.map((payee) => (
                <Tr key={payee.id}>
                  <Td>{payee.payeeAccountName}</Td>
                  <Td>{payee.bankName}</Td>
                  <Td>{payee.payeeAccountNumber}</Td>
                  <Td>{payee.ifscCode}</Td>
                  <Td onClick={() => deletePayee(payee.id)}>
                    <IconButton
                      aria-label="Delete Payee"
                      icon={<DeleteIcon color="red.500" />}
                      p={0}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>
      {showAddPayeeModal && (
        <Modal
          isOpen={showAddPayeeModal}
          onClose={() => {
            setShowAddPayeeModal(false);
            setPayeeDetails(payeeInfo);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Payee</ModalHeader>
            <ModalCloseButton />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addPayee();
              }}
            >
              <ModalBody>
                <FormControl isRequired>
                  <FormLabel mt={2}>Payee Name</FormLabel>
                  <Input
                    value={payeeDetails.payeeAccountName}
                    onChange={(e) =>
                      setPayeeDetails((cur) => {
                        return { ...cur, payeeAccountName: e.target.value };
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mt={2}>Nick name</FormLabel>
                  <Input
                    value={payeeDetails.nickName}
                    onChange={(e) =>
                      setPayeeDetails((cur) => {
                        return { ...cur, nickName: e.target.value };
                      })
                    }
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel mt={2}>Account Number</FormLabel>
                  <Input
                    value={payeeDetails.payeeAccountNumber}
                    onChange={(e) =>
                      setPayeeDetails((cur) => {
                        return { ...cur, payeeAccountNumber: e.target.value };
                      })
                    }
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel mt={2}>Bank Name</FormLabel>
                  <Input
                    value={payeeDetails.bankName}
                    onChange={(e) =>
                      setPayeeDetails((cur) => {
                        return { ...cur, bankName: e.target.value };
                      })
                    }
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel mt={2}>IFSC Code</FormLabel>
                  <Input
                    value={payeeDetails.ifscCode}
                    onChange={(e) =>
                      setPayeeDetails((cur) => {
                        return { ...cur, ifscCode: e.target.value };
                      })
                    }
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="outline"
                  color="blue.500"
                  mr={3}
                  onClick={() => {
                    setShowAddPayeeModal(false);
                    setPayeeDetails(payeeInfo);
                  }}
                >
                  Close
                </Button>
                <Button colorScheme="blue" type="submit">
                  Save
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};
